<template>

  <div class="">
    <custom-alert v-if="displayAlert==true" :message="alertMessage"
                  v-on:showedAlert="resetAlert"></custom-alert>
    <!-- Content Header (Page header) -->
    <div class="">
      <!-- <button class="btn btn-primary" @click="showText= !showText">Click Me</button> -->
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Main row -->
          <div class="row">
            <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
              <div class="card ">
                <div class="card-body table-responsive table-head">
                  <div class="row bor-bot">
                    <div class="col-md-8 pl-0">
                      <h1 class="m-0 text-dark pt-2 pb-4 ">Player Transfer Request List</h1>
                    </div>
                    <div class="col-md-4">
                      <div class="text-right pb-3">
                        <ol class="breadcrumb float-sm-right">
                          <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                          <li class="breadcrumb-item">
                            <Breadcrumbs/>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive mt-3">
                    <table class="table table-sm table-bordered table-hover" id="list_table">
                      <thead class="thead-dark">
                      <tr>
                        <th>Member ID</th>
                        <th>Player Name</th>
                        <th>Request For</th>
                        <th>Request From</th>
                        <th>Request To</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <!-- Data will be placed here -->

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <!-- /.row (main row) -->
        </div><!-- /.container-fluid -->
      </section>
    </div>
    <b-modal id="playerTransfer" ref="playerTransfer" title="Player Transfer"
             @hidden="resetTransferForm"
             @ok="handleTransferForm"
    >
      <form ref="form">
        <div class="form-group">
          <label for="reason" class="form-label">Reason <sup class="text-danger">*</sup></label>
          <textarea v-model="transferForm.reason" class="form-control" id="reason"></textarea>
          <span class="text-sm text-danger"></span>
        </div>
      </form>
    </b-modal>
    <!-- /.content -->
    <Footer></Footer>
  </div>
</template>

<script>

// import RegistrationsListTable from './RegistrationsListTable';
import $ from 'jquery';
// import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
// import 'datatables.net-responsive-dt';
import moment from 'moment';
import axios from "axios";


export default {
  name: 'registrations',
  components: {},
  data() {
    return {
      table: '',
      transferForm: {
        id: '',
        status: '',
        reason: ''
      }
    }
  },
  methods: {
    formatDate(value) {
      var date = moment(value, "YYYY-MM-DD hh:mm:ss");
      if (date.isValid()) {
        return date.format('MM/DD/YYYY');
      } else {
        return 'N/A';
      }
    },
    approveForm(tid, status) {
      this.transferForm.id = tid;
      this.transferForm.status = status;
      this.$refs['playerTransfer'].show();
    },
    resetTransferForm() {
      this.transferForm.id = '';
      this.transferForm.status = '';
      this.transferForm.reason = '';
    },
    handleTransferForm(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      axios.post(this.basePath + 'api/admin/transferPlayer/request', this.transferForm, {headers: this.adminHeaders})
          .then(function (response) {
            this.alertMessage =  response.data.message;
            this.displayAlert = true;
            this.table.draw();
            this.$refs['playerTransfer'].hide()
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
    }
  },
  mounted() {
    window.reg = this;
    this.table = $("#list_table").DataTable({
      dom: 'lfrtip',
      "bFilter": true,
      processing: true,
      serverSide: true,
      pageLength: 10,
      responsive: true,
      ajax: {
        url: process.env.VUE_APP_URL + "api/getTransferRequest",
        type: 'post'
      },
      columns: [
        {
          data: 'member.confirmation_code', name: 'member.confirmation_code', render: (data) => {
            return data || "N/A";
          }
        },
        {
          data: 'member.personal_details.full_name', name: 'member.personal_details.first_name', render: (data) => {
            return data || "N/A";
          }
        },
        {
          data: 'request_for', name: 'request_for', render: (data) => {
            return data || "N/A";
          }
        },
        {
          data: 'team.name', name: 'team_id', render: (data) => {
            return data || "N/A";
          }
        },
        {
          data: 'request_to', name: 'request_to', render: (data) => {
            return data || "";
          }
        },
        {
          data: "actions", name: "actions", orderable: false, searchable: false,
          render: (data, type, row, meta) => {
            return `<a  href="javascript:void(0)" data-id="${row.id}" title="Approve" class="action-approve"><i class="fas fa-check  text-green"></i></a> <a title="Reject" class="action-reject" data-id="${row.id}" href="javascript:void(0);"><i class="fas fa-ban  text-danger"></i></a>`

          }
        },

      ],
      order: [[0, "desc"]]
    });
    var vueRouter = this.$router;
    document.addEventListener("click", function (e) {
      if (e.target && e.target.parentNode.className == "action-approve") {
        var aid = e.target.parentNode.getAttribute('data-id');
        window.reg.approveForm(aid, 1);
      }
      if (e.target && e.target.parentNode.className == "action-reject") {
        var rid = e.target.parentNode.getAttribute('data-id');
        window.reg.approveForm(rid, 2);
      }
    });
  },

  computed: {}
}
</script>